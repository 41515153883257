import {
    AdvertEstimationWatch,
    Condition,
    Construction,
    Country,
    Currency,
    Disposition,
    Equipped,
    EstateType,
    LandType,
    MarketingConsentStatus,
    OfferType,
    Ownership,
    Package,
    Penb,
    SelectedPackageInput,
} from '.cache/__types__';
import {WannaMoreMapping} from '@constants/WannaMore';
import {TopTerm} from '@liveComponents/AdvertPropertyCard/typesTop';
import {Image} from '@liveComponents/ImageUpload/ImageUpload';
import {Feature, Geometry} from 'geojson';
import {AutocompleteProperties} from '@liveComponents/Autocomplete/autocompleteHelper';
import {VoucherType} from '@pageComponents/MyBezrealitky/Highlight/typesHighlight';

export type GpsType = {
    lat: number;
    lng: number;
};

export type State = {
    step: Step;
    path: Step[];
    user?: {
        id: string;
        verified: boolean;
        phoneVerified: boolean;
        isDeveloper: boolean;
        marketingConsentStatus?: MarketingConsentStatus | null;
    };
    advert?: {
        // IMPORTANT! Any change should be reflected in Save component as the data from here are in most cases used directly in mutation
        id?: string;
        project?: string;
        externalId?: string;
        offerType?: OfferType;
        estateType?: EstateType;
        landType?: LandType;
        ownership?: Ownership;
        comfort?: boolean;
        address?: string;
        gps?: GpsType;
        isTSRegion?: boolean;
        addressFeature?: Feature<Geometry, AutocompleteProperties>;
        etage?: number;
        totalFloors?: number;
        roommate?: boolean; // Spolubydlení
        disposition?: Disposition;
        surface?: number;
        surfaceLand?: number;
        condition?: Condition;
        construction?: Construction;
        penb?: Penb;
        description?: string;
        descriptionEnglish?: string;
        equipped?: Equipped;
        balconySurface?: number;
        loggiaSurface?: number;
        terraceSurface?: number;
        frontGarden?: number;
        parking?: boolean;
        garage?: boolean;
        barrierFree?: boolean;
        lift?: boolean;
        cellarSurface?: number;
        images?: Image[];
        currency?: Currency;
        price?: number;
        charges?: number;
        utilities?: number;
        fee?: number;
        chargesNote?: string;
        utilitiesNote?: string;
        deposit?: number;
        availableFrom?: number;
        petFriendly?: boolean;
        houseUnit?: string;
        estimationWatch?: AdvertEstimationWatch;
        requireInsurance?: boolean;
        requireCreditcheck?: boolean;
    };
    country: Country;
    payment?: {
        id?: string;
        package?: Package;
        additionalPackages?: Package[];
        topPackages?: SelectedPackageInput[];
        tops?: TopTerm[]; // Deprecated
        topPackage?: Package; // Deprecated
        voucher?: VoucherType;
    };
    estimate?: {
        price: number | null;
        currency: Currency | null;
    };
};

export enum ActionType {
    Reset,
    GoTo,
    ToLogin,
    ToRegister,
    Login,
    VerifyEmail,
    VerifyPhone,
    SetProject,
    SetOfferType,
    SetEstateType,
    SetRoommate,
    SetLandType,
    SetLocality,
    SetFloor,
    SetSize,
    SetConstruction,
    SetPenb,
    SetDescription,
    SetDescriptionSilent,
    SetEquipment,
    SetImages,
    SetPrice,
    SetAvailability,
    SetRequirements,
    Save,
    ConfirmPreview,
    SetPackage,
    SetPromokit,
    SetAdditionalPackages,
    SetVoucher,
    SetPayment,
    BeginNemoreport,
    SetUnit,
    SetOwnership,
    SetEstimate,
    GoBack,
    SetAdvertId,
}

type Reset = {
    type: ActionType.Reset;
};

type GoTo = {
    type: ActionType.GoTo;
    value: Step;
};

type Login = {
    type: ActionType.Login;
    value: NonNullable<State['user']>;
};

type VerifyEmail = {
    type: ActionType.VerifyEmail;
};

type VerifyPhone = {
    type: ActionType.VerifyPhone;
};

type SetProject = {
    type: ActionType.SetProject;
    value?: string;
    attr: {
        externalId?: string;
    };
};

type SetOfferType = {
    type: ActionType.SetOfferType;
    value: OfferType;
};

type SetRoommate = {
    type: ActionType.SetRoommate;
    roommate: boolean;
};

type SetEstateType = {
    type: ActionType.SetEstateType;
    value: EstateType;
};

type SetLandType = {
    type: ActionType.SetLandType;
    value: LandType;
};

type SetLocality = {
    type: ActionType.SetLocality;
    value: string;
    attr: {
        gps: GpsType;
        country: Country;
        isTSRegion?: boolean;
        addressFeature?: Feature<Geometry, AutocompleteProperties>;
        currency: Currency;
    };
};

type SetFloor = {
    type: ActionType.SetFloor;
    value: number;
    attr: {
        total: number;
        houseUnit?: string;
        // separated part of equipment #20250
        lift?: boolean;
        barrierFree?: boolean;
    };
};

type SetSize = {
    type: ActionType.SetSize;
    value?: number;
    attr: {
        disposition?: Disposition;
        surfaceLand?: number;
    };
};

type SetConstruction = {
    type: ActionType.SetConstruction;
    attr: {
        condition: Condition;
        construction: Construction;
    };
};

type SetPenb = {
    type: ActionType.SetPenb;
    value: Penb;
};

type SetDescription = {
    type: ActionType.SetDescription;
    value: string;
    attr: {
        descriptionEnglish: string;
    };
};

type SetDescriptionSilent = {
    type: ActionType.SetDescriptionSilent;
    value: string;
};

type SetEquipment = {
    type: ActionType.SetEquipment;
    value: Equipped;
    attr: Partial<WannaMoreMapping>;
};

type SetImages = {
    type: ActionType.SetImages;
    value: Image[];
};

type SetPrice = {
    type: ActionType.SetPrice;
    attr: {
        currency: Currency;
        price: number;
        charges?: number;
        utilities?: number;
        deposit?: number;
        chargesNote?: string;
        utilitiesNote?: string;
        fee?: number;
    };
};

type SetAvailability = {
    type: ActionType.SetAvailability;
    value: number;
};

type SetRequirements = {
    type: ActionType.SetRequirements;
    value: {
        requireInsurance: boolean;
        requireCreditcheck: boolean;
    };
};

type Save = {
    type: ActionType.Save;
    value: string;
};

type ConfirmPreview = {
    type: ActionType.ConfirmPreview;
};

type SetPackage = {
    type: ActionType.SetPackage;
    value: Package;
};

type SetAdditionalPackages = {
    type: ActionType.SetAdditionalPackages;
    value: Package[];
};

type SetVoucher = {
    type: ActionType.SetVoucher;
    voucher: VoucherType;
};

type SetPayment = {
    type: ActionType.SetPayment;
    value: string;
};

type SetOwnership = {
    type: ActionType.SetOwnership;
    value: Ownership;
    attr: {
        houseUnit?: string;
    };
};

type SetEstimate = {
    type: ActionType.SetEstimate;
    value: {
        price: number | null;
        currency: Currency | null;
    };
};

type GoBack = {
    type: ActionType.GoBack;
};

type SetAdvertId = {
    type: ActionType.SetAdvertId;
    value: string;
};

export type Action =
    | Reset
    | GoTo
    | Login
    | VerifyEmail
    | VerifyPhone
    | SetProject
    | SetOfferType
    | SetEstateType
    | SetRoommate
    | SetLandType
    | SetLocality
    | SetFloor
    | SetSize
    | SetConstruction
    | SetPenb
    | SetDescription
    | SetDescriptionSilent
    | SetEquipment
    | SetImages
    | SetPrice
    | SetAvailability
    | SetRequirements
    | Save
    | ConfirmPreview
    | SetPackage
    | SetAdditionalPackages
    | SetVoucher
    | SetPayment
    | SetOwnership
    | SetEstimate
    | GoBack
    | SetAdvertId;

export enum Step {
    // IMPORTANT ny additional step should be mapped into group in InsertBox component
    Login = 'Login',
    Registration = 'Registration',
    EmailVerification = 'EmailVerification',
    PhoneVerification = 'PhoneVerification',
    ProjectSelection = 'ProjectSelection',
    OfferTypeSelection = 'OfferTypeSelection',
    EstateTypeSelection = 'EstateTypeSelection',
    LandTypeSelection = 'LandTypeSelection',
    LocalitySelection = 'LocalitySelection',
    RoommateSelection = 'RoommateSelection',
    FloorSelection = 'FloorSelection',
    SizeSelection = 'SizeSelection',
    ConstructionSelection = 'ConstructionSelection',
    PenbSelection = 'PenbSelection',
    Description = 'Description',
    EquipmentSelection = 'EquipmentSelection',
    UtilitiesSelection = 'UtilitiesSelection',
    Gallery = 'Gallery',
    OwnershipSelection = 'OwnershipSelection',
    PriceSelection = 'PriceSelection',
    AvailabilitySelection = 'AvailabilitySelection',
    RequirementsSelection = 'RequirementsSelection',
    Preview = 'Preview',
    PackageSelection = 'PackageSelection',
    AdditionalPackagesSelection = 'AdditionalPackagesSelection',
    Payment = 'Payment',
    Lead = 'Lead',
    Thanks = 'Thanks',
    Final = 'Final',
}

export const anonymousSteps = [
    // Steps that don't need user to be logged in
    Step.Login,
    Step.Registration,
    Step.EmailVerification,
];

export const paymentSteps = [
    // From those steps you cannot move back to advert edit
    Step.Preview,
    Step.PackageSelection,
    Step.AdditionalPackagesSelection,
    Step.Payment,
    Step.Lead,
];

export const postPaymentSteps = [Step.Thanks, Step.Final];
