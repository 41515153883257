import NextImage, {StaticImageData} from 'next/image';
import classNames from 'classnames';
import {HeroIntro} from '@components/HeroIntro';

import styles from './HeroIntroBackroundMD.module.scss';
import {FC} from 'react';

type HeroIntroBackroundMDProps = {
    bgImg: StaticImageData;
    bgImgMobile: StaticImageData;
    alt: string;
    gradient?: boolean;
};

export const HeroIntroBackroundMD: FC<React.PropsWithChildren<HeroIntroBackroundMDProps>> = ({bgImg, bgImgMobile, alt, gradient}) => (
    <HeroIntro.Background gradient={gradient}>
        <span className={classNames(styles.hideMobile)}>
            <NextImage
                src={bgImg}
                alt={alt}
                placeholder={'blur'}
                fill
                sizes="100vw"
                style={{
                    objectFit: 'cover',
                }}
            />
        </span>
        <span className={classNames(styles.hideTabletDesktop)}>
            <NextImage
                src={bgImgMobile}
                alt={alt}
                placeholder={'blur'}
                fill
                sizes="100vw"
                style={{
                    objectFit: 'cover',
                }}
            />
        </span>
    </HeroIntro.Background>
);
